@import '../../scss/variables.scss';

.task {
    width: 100%;
    height: 80px;
    background: #ffffff;
    margin-bottom: 20px;
    box-shadow: $box-shadow;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;

    .title {
        flex-grow: 1;
        padding: 30px 0;
    }

    .checkbox {
        $border-width: 3px;
        $border-radius: 10px;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 0;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.checked {
            &:after {
                background: transparent;
            }
        }

        &:before {
            border-radius: $border-radius;
            content: '';
            background: $gradient;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: $border-radius;
            position: absolute;
            z-index: -2;
        }

        &:after {
            content: '';
            border-radius: $border-radius;
            position: absolute;
            transition: 300ms ease;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: #fff;
            border: $border-width solid transparent;
            background-clip: padding-box;
        }
    }

    @media screen and (min-width:1250px) {

        width: calc(50% - 50px);
        margin-right: 50px;
        margin-bottom: 50px;

        .title {
            font-size: 24px;
        }
    }
}