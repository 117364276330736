@import '../../scss/variables.scss';

.overview {
    .overview-grid {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: -20px;
    }

    .topic {
        position: relative;
        width: calc(50% - 20px);
        background: #ffffff;
        margin-right: 20px;
        margin-bottom: 20px;
        padding-bottom: calc(50% - 20px);
        box-shadow: $box-shadow;
        border-radius: 16px;

        &.done {
            opacity: 0.7;
        }
    }

    .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        line-height: 1.3;
        padding: 10px;
    }

    .icon {
        height: 60px;
        margin-bottom: 5px;
        padding: 2px;
    }

    .title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
    }

    @media screen and (min-width:1250px) {
        .overview-grid {
            margin-right: -60px;
        }
        
        .heading {
            &.medium {
                font-size: 34px;
            }
        }

        .topic {
            width: calc(25% - 60px);
            margin-right: 60px;
            margin-bottom: 60px;
            padding-bottom: calc(25% - 60px);
        }

        .icon {
            height: 70px;
        }

        .title {
            font-size: 18px;
        }
    }
}