@import '../../scss/variables.scss';

.profile-section {
    padding: 0 30px;

    .avatar-large {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .heading {
        text-align: center;
    }

    .profile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 25px;

        .avatar {
            margin-bottom: 25px;
            cursor: pointer;
        }
    }

    @media screen and (min-width:1250px) {

        .heading {
            &.medium {
                font-size: 34px;
            }
        }

        .profile-container {
            margin-right: -90px;
            .avatar {
                height: 0;
                margin-right: 90px;
                width: calc(33.33% - 90px);
                padding-bottom: calc(33.33% - 106px);

            }
        }
    }
}