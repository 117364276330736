@import '../../scss/variables.scss';

.avatar {
    width: 100px;
    height: 100px;
    border: 7px solid #649C66;
    border-radius: 50%;
    position: relative;
    transition: 300ms ease;

    .icon {
        height: 87px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    &.large {
        width: 110px;
        height: 110px;
    }

    @media screen and (min-width:1250px) {
        width: 130px;
        height: 130px;
        border: 8px solid #649C66;
        overflow: hidden;

        .icon {
            height: 90%;
        }

        &.large {
            width: 200px;
            height: 200px;
        }
    }
}