@import '../../scss/variables.scss';

.nav {
    width: 100%;
    height: 80px;
    background: $gradient;
    border-radius: 30px 30px 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    z-index: 40;
    .nav-icon {
        width: 20px;
        height: 25px;
    }
    .nav-item {
        transform: translateY(0);
        display: block;
        position: relative;
        &:before {
            display: block;
            width: 100%;
            height: 2px;
            background: #ffffff;
            content: ' ';
            transition: 300ms ease;
            transform: scaleX(0);
            position: absolute;
            left: 0;
            bottom: -5px;
            border-radius: 1px;
        }
        &.active {
            transform: translateY(-2px);
            &:before {
                transform: scaleX(1);
            }
        }
    }
    @media screen and (min-width:1250px) {
        width: auto;
        height: 100vh;
        border-radius: 0 30px 30px 0;
        padding: 0;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 100px;
        .nav-item {
            margin-bottom: 60px;
            padding-left: 40px;
            padding-right: 40px;
            line-height: 1;
            &:before {
                left: 0;
                top: 0;
                width: 5px;
                height: 100%;
                transform: scaleY(0);
            }
            &.active {
                transform: none;
                &:before {
                    transform: scaleY(1);
                }
            }
        }
    }
}