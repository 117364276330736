@import '../../scss/variables.scss';

.introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin-bottom: -120px;
    .pop-over {
        height: auto;
        animation: none;
        border-radius: 45px 45px 0 0;
        position: static;
        transform: none;
        max-height: none;
  }

    .illustration {
        width: 100%;
        flex-grow: 1;
        position: relative;
        img {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -40%);
            width: 100%;
            left: 50%;
        }
    }
}