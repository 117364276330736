.info-page {
    position: relative;

    .back {
        position: absolute;
        top: 35px;
        left: 30px;
        display: flex;
        z-index: 10000;
        border: none;
        background: transparent;
        align-items: center;

        img {
            height: 24px;
            margin-right: 8px;
        }

        @media screen and (min-width:1250px) {
            font-size: 20px;
        }
    }
}