@import '../../scss/variables.scss';

.header {
    color: #ffffff;
    width: 100%;
    background: $gradient;
    border-radius: 0 0 45px 45px;
    padding-top: 35px;
    padding-bottom: 40px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);

    .back {
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-left: -10px;
        padding-bottom: 10px;
        font-size: 14px;
        background: transparent;
        border: none;

        .arrow {
            width: 13px;
            margin-right: 8px;
        }
    }

    .navigation-container {
        display: flex;
        justify-content: space-between;
        
        .info {
            width: 32px;
        }
    }

    @media screen and (min-width:1250px) {
        .heading {
            &.large {
                font-size: 44px;
            }

            span.small {
                font-size: 24px;
            }
        }

        .back {
            font-size: 20px;
            cursor: pointer;

            .arrow {
                width: 15px;
                cursor: pointer;
            }
        }
    }
}