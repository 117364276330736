@import '../../scss/variables.scss';

.stats-section {
    padding: 0;

    .avatar-large {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .stats-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -20px;
        margin-bottom: -50px;
    }

    .progress-container {
        width: calc(50% - 20px);
        margin-right: 20px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            text-align: center;
            margin-top: 10px;
        }
    }

    @media screen and (min-width:1250px) {
        .stats-container {
            margin-right: -20px;
        }

        .heading {
            &.medium {
                font-size: 34px;
            }
        }

        .progress-container {
            width: calc(25% - 20px);
            h3 {
                font-size: 24px;
            }
        }
    }
}