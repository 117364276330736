@import '../../scss/variables.scss';

.pop-over-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    &.closed {
        .pop-over-background {
            opacity: 0;
        }

        .pop-over {
            transform: translate(-50%, 100%);
        }
    }
}

.pop-over-background {
    transition: 300ms ease;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #353535;
    opacity: 0.5;
    animation: background 300ms ease;
}

@keyframes background {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}

.pop-over {
    transition: 300ms ease;
    width: 100%;
    max-height: 65vh;
    background: $bg;
    border-radius: 45px 45px 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 200;
    padding-top: 60px;
    padding-bottom: 80px;
    box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.1);
    overflow: auto;
    animation: moveUp 300ms ease;
    left: 50%;
    transform: translate(-50%, 0);

    @keyframes moveUp {
        from {
            transform: translate(-50%, 100%);
        }

        to {
            transform: translate(-50%, 0);
        }
    }

    .heading {
        &.medium {
            margin-top: 0;
        }
    }

    &.pull {
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background: $gradient;
            height: 5px;
            width: 80px;
            border-radius: 15px;
            top: 20px;
        }
    }

    @media screen and (min-width:960px) {
        max-width: 960px;
        height: 500px;
    }
}