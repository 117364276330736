@import '../../scss/variables.scss';

.task-section {
    .heading-container {
        display: flex;
        padding-top: 20px;
        padding-bottom: 30px;

        .heading {
            &.small {
                padding-left: 10px;
                font-size: 20px;
            }
        }
    }
    
    .task-container {
        .completed {
            font-weight: bold;
            font-size: 18px;
        }
    }

    .accordion {
        margin: 0 -10px;
        padding: 0 10px;

        .task-container {
            transition: 300ms ease;
            opacity: 0;
            max-height: 0;
            margin-top: -20px;
        }

        .heading-container {
            cursor: pointer;
            user-select: none;
            img {
                transition: 300ms ease;
            }
        }

        &.open {
            .task-container {
                opacity: 1;
                max-height: 2000px;
                margin-top: 0;
            }

            .heading-container {
                img {
                    transform: rotate(90deg);
                }
            }
        }
    }

    @media screen and (min-width:1250px) {
        .heading {
            &.medium {
                font-size: 34px;
            }
        }

        .heading-container {
            img {
                cursor: pointer;
            }
            .heading {
                &.small {
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }

        .task-container {
            display: flex;
            flex-wrap: wrap;
            margin-right: -50px;
            .completed {
                font-weight: bold;
                font-size: 28px;
            }
        }
    }
}