.progress {
    path {
        stroke-width: 8;
        stroke-linecap: round;
        stroke: url(#gradient);
        fill: none;
    }

    circle {
        fill: none;
        stroke: #F2F2F2;
        stroke-width: 8;
    }

    position: relative;
    height: 110px;
    width: 110px;

    svg {
        width: inherit;
        height: inherit;

        path {
            width: inherit;
            height: inherit;
        }
    }

    .percent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
        font-size: 24px;
        width: 100%;
        text-align: center;
        font-weight: bold;
    }

    @media screen and (min-width:1250px) {
        width: 130px;
        height: 130px;

        .percent {
            font-size: 28px;
        }
    }
}