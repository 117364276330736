@import 'scss/variables.scss';

.app-wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-bottom: 120px;
}

body {
    font-family: $font-family;
    font-size: 18px;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    color: $font-color;
    background: $bg;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.heading {
    font-weight: $weight-bold;
    margin-top: 0;
    line-height: 1.1;
    &.medium {
        font-size: 24px;
        margin-bottom: 25px;
        margin-top: 45px;
    }
    &.large {
        font-size: 34px;
        margin-bottom: 15px;
        .green {
            background: $gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .small {
            font-size: 20px;
            font-weight: normal;
            line-height: 1.6;
            display: block;
        }
    }
}

a {
    text-decoration: none;
    color: $font-color;
}

.button {
    display: inline-block;
    padding: 14px 40px;
    font-weight: $weight-bold;
    font-size: 24px;
    background: $gradient;
    border-radius: 25px;
    color: #ffffff;
    box-shadow: $box-shadow;
    margin-top: 30px;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.content-container {
    padding: 0 40px;
}


